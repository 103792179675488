.div-swiper-content-profile {
  display: flex;
  flex: 1;
  background-color: #575757;
  border-radius: 5px;
  margin-top: -70px;
  flex-direction: column;
}
.div-head-profile {
  display: flex;
  /* background-color: yellow; */
  width: 100%;
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
}
.div-carousel {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.name-text {
  color: #fff;
  font-size: 35px;
  margin-top: 30px;
}
.right-btns {
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}
.right-btn-div {
  display: flex;
  flex-direction: row;
  margin-top: 55px;
}
.body-div {
  display: flex;
  flex: 1;
  background-color: gray;
  padding: 30px;
  flex-direction: column;
  /* overflow: scroll; */
}
.div-popularity {
  display: flex;
  width: 100%;
  height: 90px;
  /* background-color: green; */
  justify-content: space-around;
}
.popularity-content {
  display: flex;
  /* background-color: hotpink; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.icon-up {
  color: white;
  font-size: 25px;
  margin-top: 9px;
  margin-bottom: 5px;
}
.text1 {
  color: white;
  margin: 0px;
}
.text2 {
  color: white;
  font-size: 25px;
  margin: 0px;
}
.text3 {
  color: #fff;
  margin: 0px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #707070;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
}
.div-line {
  width: 100%;
  height: 1px;
  background-color: #a5a5a5;
  margin-top: 10px;
  margin-bottom: 10px;
}
.work-education {
  display: flex;
  /* background-color: green; */
  flex-direction: column;
}
.text-heading {
  font-size: 22px;
  color: white;
  margin: 0px;
}
.text-para {
  color: white;
  font-size: 13px;
  margin: 0px;
}
.add-now {
  color: white;
  font-size: 12px;
  border: 1px solid #707070;
  width: 60px;
  border-radius: 7px;
  padding-left: 5px;
  margin-top: 10px;
}
.done-btn {
  border: 0px solid #fff;
  background-color: #6e48db;
  display: flex;
  align-self: flex-start;
  margin-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  border-radius: 5px;
}
.save-btn {
  border: 0px solid #fff;
  background-color: #6e48db;
  display: flex;
  align-self: center;
  margin-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  border-radius: 5px;
}
.div-info {
  display: flex;
  /* background-color: teal; */
  flex-direction: row;
  margin-top: 10px;
}
.info-text {
  color: #fff;
  font-size: 16px;
}
.select-class {
  width: 50%;
}
.select-class {
  width: 50%;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .div-swiper-content-profile {
    width: 100vh;
  }
  .select-class {
    width: 100%;
  }
  .select-class {
    width: 100%;
  }
  .div-head {
    padding-left: 10px;
    padding-right: 10px;
  }
  .right-btns {
    margin-left: 2px;
  }
  .div-head-profile {
    padding-left: 10px;
    padding-right: 10px;
  }
  .name-text {
    font-size: 22px;
    margin-top: 45px;
    width: 50%;
  }
}
